// import logo from './logo.svg';
import "./App.css";
import logo from "./assets/Design_2.png";
import HeroSection from "./components/HeroSection/HeroSection.tsx";

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img
          src={logo}
          className="App-logo"
          alt="logo"
        />
        <p>Em construção...</p>
      </header> */}

      <HeroSection />
    </div>
  );
}

export default App;
