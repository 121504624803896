import * as React from "react";
import video from "../../assets/mp4/trailer-moving.mp4";
import logo from "../../assets/Design_2.png";
import './AnimatedFromTheLeft.css';

function HeroSection() {
  return (
    <div style={{ display: "flex", flex: 1 }}>
      <video
        src={video}
        autoPlay
        loop
        muted
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: -1,
        }}
      />
      <div
        style={{
          flex: 1,
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          background:
            "linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.2) 100%)",
          flexDirection: "column",
        }}
      >
        <img
          src={logo}
        //   className="App-logo"
          className="animated-text"
          alt="logo"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
        <div style={{ flex: 2 }} />
      </div>
    </div>
  );
}

export default HeroSection;
